import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";
import SEO from "../components/seo";
import { LINKS } from "../constants/links";
import AppButton from "../components/common/button";
import AppPageHeader from "../components/common/texts/appPageHeader";
import AppLeadingText from "../components/common/texts/appLeadingText";
import { clearCookiesBeforeLogin, isCameraError } from "../utils/cookies";
import { AppContainer } from "../components/common/appContainer";

const StyledButtonWrapper = styled.div`
	text-align: center;
`;

const PaymentFailed = () => {
	clearCookiesBeforeLogin();
	const { t } = useTranslation();
	return (
		<>
			<SEO title={t("paymentFailed.title")} />
			<AppContainer>
				<AppPageHeader>{t("paymentFailed.header")}</AppPageHeader>
				<AppLeadingText>{t("paymentFailed.description")}</AppLeadingText>
				<StyledButtonWrapper>
					<AppButton as={Link} to={isCameraError() ? LINKS.ENTER_CODE : LINKS.SCAN_QR}>
						{t("common.tryAgain")}
					</AppButton>
				</StyledButtonWrapper>
			</AppContainer>
		</>
	);
};

export default PaymentFailed;
